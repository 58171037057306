import React from "react"
import {
  ContactCardWrapper,
  ContactCardHeading,
  ContactCardSubText,
  ContactCardIcon,
  Address,
} from "./style"
import { Box } from "UI/Box"
import AvenueImg from "UI/AvenueImg"

const ContactCard = ({
  mapImage,
  address,
  emailEnquiry,
  phone,
  headOffice,
}) => {
  const { srcSet, sourceUrl } = mapImage || {}

  return (
    <Box background={"#008CD1"} mb={[18, 0]} mt={[12, 0]}>
      <ContactCardWrapper>
        <ContactCardHeading>Our contact details</ContactCardHeading>

        {phone && (
          <ContactCardSubText>
            <ContactCardIcon name="phone-call" />
            <a
              onClick={() => {
                window.gtag("event", "click", {
                  event_category: "contact-phone-number",
                })
              }}
              href={`tel:${phone}`}
            >
              {phone}
            </a>
          </ContactCardSubText>
        )}
        <ContactCardSubText>
          <ContactCardIcon name="letter" />
          <a
            onClick={() => {
              window.gtag("event", "click", {
                event_category: "contact-email-address",
              })
            }}
            href={`mailto: ${emailEnquiry}`}
          >
            {emailEnquiry}
          </a>
        </ContactCardSubText>

        {address && (
          <ContactCardSubText headOffice={headOffice}>
            <ContactCardIcon name="home-2" />
            {headOffice && `Head office`}
            <Address headOffice={headOffice}>
              <a
                href={`http://maps.google.com/?q=${address}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {address}
              </a>
            </Address>
          </ContactCardSubText>
        )}
      </ContactCardWrapper>

      <AvenueImg fluid={{ srcSet, sourceUrl, aspectRatio: 1.61 }} />
    </Box>
  )
}

export default ContactCard
