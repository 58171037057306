import React from "react"
import { Heading } from "../LatestResources/style.js"
import { Grid, Row, Col } from "components/UI/Grid"
import { Spacer, Box, Line, StyledLink } from "UI"
import { WhiteTeaser } from "../WhiteTeaser"
import { useStaticQuery, graphql } from "gatsby"

const RelatedPages = ({ pageSettingsData }) => {
  const data = useStaticQuery(
    graphql`
      {
        wp {
          siteOptions {
            siteSettings {
              siteRelatedPages {
                heading
                defaultPages {
                  ... on WpPage {
                    title
                    excerpt
                    uri
                  }
                }
                viewAllPage {
                  ... on WpPage {
                    uri
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const hideRelatedPages = pageSettingsData?.hideRelatedPages
  const relatedPages = pageSettingsData?.relatedPages
  const relatedHeading = pageSettingsData?.relatedHeading
  const showRelated = pageSettingsData && relatedPages?.length
  const defaultRecommended = data.wp.siteOptions?.siteSettings?.siteRelatedPages

  const heading = showRelated ? relatedHeading : defaultRecommended.heading
  const pages = showRelated ? relatedPages : defaultRecommended.defaultPages
  const viewAll = defaultRecommended?.viewAllPage?.uri

  if (hideRelatedPages) return <Spacer height={0} />

  return (
    <Box background="#F5F5F5" p={["40px 0", "60px 0", "80px 0"]}>
      <Grid mainColumn>
        <Heading>{heading}</Heading>
        <Spacer height={[25, 40]} />
        <Row smGap={30} mdGap={40}>
          {pages.map((page, index) => {
            return (
              <Col
                xs={12}
                sm={4}
                md={4}
                xsAlignX="center"
                mdAlignX="start"
                key={Math.random() + index}
              >
                <WhiteTeaser
                  title={page.title}
                  excerpt={page.excerpt}
                  to={page.uri}
                />
              </Col>
            )
          })}
        </Row>
        {viewAll && !showRelated && (
          <>
            <Line />
            <Box width="100%">
              <StyledLink to={viewAll} mt={[14, 35]} ml="auto">
                View all
              </StyledLink>
            </Box>
          </>
        )}
      </Grid>
    </Box>
  )
}

export default RelatedPages
