import React from "react"
import { ContactPageContainer } from "./style"
import ContactCard from "./ContactCard"
import ContactForm from "./ContactForm"
import { Grid, Row, Col } from "UI/Grid"
import { WhiteTeaser } from "components/Project/WhiteTeaser"
import { RelatedPages } from "components/Project/RelatedPages"
import { Box, Spacer, EmailSubscription, Line } from "UI"
import { Content } from "UI"
import { LatestResources } from "../../Project/LatestResources"

const ContactTemplate = ({ content, contactData }) => {
  const { mapImage, address, emailEnquiry, phone, headOffice } =
    contactData.cdDetails || {}

  const { ctaPage } = contactData || {}

  return (
    <>
      <Box mt={[0, 0, `-70px`]} />
      <ContactPageContainer>
        <Grid>
          <Row smGap={40} mdGap={40} lgGap={40}>
            <Col xs={12} sm={12} md={8} lg={8}>
              <ContactForm country={contactData.country} />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} order={[-1, -1]}>
              <Row xsGap={2} smGap={40}>
                <Col xs={12} sm={6} md={12}>
                  <ContactCard
                    address={address}
                    emailEnquiry={address}
                    mapImage={mapImage}
                    phone={phone}
                    emailEnquiry={emailEnquiry}
                    headOffice={headOffice}
                  />
                </Col>
                <Col xs={12} sm={6} md={12}>
                  {ctaPage && (
                    <WhiteTeaser
                      title={ctaPage?.title}
                      excerpt={ctaPage?.excerpt}
                      to={ctaPage?.uri}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12}>
              <Spacer height={[25, 40]} />

              <Line />
              <Spacer height={[25, 90]} />

              <Content noIntroStyles>{content}</Content>
            </Col>
          </Row>
        </Grid>
      </ContactPageContainer>
      <Box mt={[20, null, 50]} />

      <LatestResources noMarginTop handpickedData={contactData.relatedPosts} />

      <Box mt={[10, 45, 90]} />

      <EmailSubscription />

      <RelatedPages />

      <Box mb={[-10, -90, -120]} />
    </>
  )
}

export default ContactTemplate
