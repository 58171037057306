import React from "react"
import {
  ContactFormContainer,
  Form,
  ContactFormHeading,
  ContactFormText,
  SubmitButton,
  ContactTextInput,
  MandatoryMessage,
} from "./style"

import { Box } from "UI/Box"

import { useForm } from "UI/Form/useForm"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import { Button } from "UI/Button"

const CREATE_CONTACT_ENQUIRY = gql`
  mutation CreateContactEnquiry(
    $fullName: String!
    $email: String!
    $company: String!
    $phone: String!
    $message: String!
    $hear: String!
    $country: String!
  ) {
    createContactEnquiry(
      input: {
        clientMutationId: "Contact enquiry"
        fullName: $fullName
        email: $email
        company: $company
        phone: $phone
        message: $message
        hear: $hear
        country: $country
      }
    ) {
      data
      success
    }
  }
`

const ContactForm = ({ country }) => {
  const { handleSubmit, inputProps, setSubmitted, submitted } = useForm()

  const onSubmit = (data) => {
    if (window.gtag) {
      window.gtag("event", "click", {
        event_category: "contact-form-submission",
      })
    }

    setSubmitted(true)

    createContactEnquiry({
      variables: {
        fullName: data.fullName.length ? data.fullName : "n/a",
        company: data.company.length ? data.company : "n/a",
        email: data.email.length ? data.email : "n/a",
        phone: data.phone.length ? data.phone : "n/a",
        message: data.message.length ? data.message : "n/a",
        hear: data.hear.length ? data.hear : "n/a",
        country: country,
      },
    })
  }

  const [createContactEnquiry] = useMutation(CREATE_CONTACT_ENQUIRY)

  return (
    <ContactFormContainer>
      <ContactFormHeading>
        {submitted
          ? "Thank you for your message."
          : "We'd love to hear from you."}
      </ContactFormHeading>

      {submitted ? (
        <ContactFormText>
          One of our representatives will be back in touch within 48 hours.{" "}
        </ContactFormText>
      ) : country === "Australia" ? (
        <ContactFormText>
          Let us know how Lánluas can assist you and we’ll put you in touch with
          one of our specialised consultants.
          {/* <a
            onClick={() => {
              window.gtag("event", "click", {
                event_category: "contact-phone-number",
              })
            }}
            href={"tel:1300 924 007"}
          >
            1300 924 007
          </a>
          .{" "} */}
        </ContactFormText>
      ) : (
        <ContactFormText>
          Let us know how Lánluas can assist you and we’ll put you in touch with
          one of our specialised consultants.
        </ContactFormText>
      )}

      {submitted ? <Button to="/">Back to the homepage</Button> : null}
      <Form onSubmit={handleSubmit(onSubmit)} submitted={submitted}>
        <ContactTextInput
          name="fullName"
          label="Your name"
          required
          errorMessage="Please enter your name"
          {...inputProps}
        />

        <ContactTextInput
          label="Email address"
          name="email"
          type="email"
          required
          errorMessage="Please enter an email"
          {...inputProps}
        />

        <ContactTextInput
          label="Company"
          name="company"
          type="company"
          errorMessage="Please enter your company name"
          {...inputProps}
        />

        <ContactTextInput
          label="Phone"
          name="phone"
          type="phone"
          errorMessage="Please enter a phone number"
          {...inputProps}
        />
        <ContactTextInput
          label="Message"
          name="message"
          type="textarea"
          required
          errorMessage="Please enter a message"
          {...inputProps}
        />

        <ContactTextInput
          label="How did you first hear about Lánluas?"
          name="hear"
          type="textarea"
          required
          errorMessage="Please let us know how you heard about us"
          smallTextArea
          {...inputProps}
        />
        <Box
          direction={["column", "row"]}
          justifyContent="space-between"
          alignItems={["left", "center"]}
          mt={30}
        >
          <MandatoryMessage>Denotes mandatory fields.</MandatoryMessage>

          <SubmitButton type="submit" label="Submit">
            Submit enquiry
          </SubmitButton>
        </Box>
      </Form>
    </ContactFormContainer>
  )
}

export default ContactForm
