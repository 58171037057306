import styled from "styled-components"
import { device } from "breakpoints"
import { Box } from "UI/Box"
import { Button } from "UI/Button"
import { Icon } from "UI/Icon"
import { MainColumn } from "UI/MainColumn"
import { TextInput } from "UI/Form/TextInput"
import { Input, TextInputErrorMessage } from "UI/Form/TextInput/style"
import { Label, InputSectionWrapper } from "UI/Form/style"

export const ContactPageContainer = styled(MainColumn)`
  margin-top: 30px;

  @media ${device.tablet} {
    margin-top: 75px;
  }

  .row > .col:nth-last-of-type(1) > ${Box} {
    height: auto;
    margin-bottom: 30px;

    @media ${device.tablet} {
      margin-bottom: 0px;
    }
  }
`

export const ContactFormContainer = styled.div`
  ${InputSectionWrapper} {
    margin-top: 14px;
    margin-bottom: 0px;

    @media ${device.tablet} {
      margin-top: 24px;
    }
  }
`

export const ContactFormHeading = styled.h3`
  margin-bottom: 10px;
  letter-spacing: -0.01em;
  color: #1870b0;
  font-weight: bold;
  font-size: 20px;
  line-height: 125%;

  @media ${device.tablet} {
    font-size: 24px;
    margin-bottom: 20px;
  }
`

export const ContactFormText = styled.p`
  margin-bottom: 21px;
  letter-spacing: -0.01em;
  font-size: 16px;
  line-height: 150%;

  @media ${device.tablet} {
    margin-bottom: 34px;
    font-size: 18px;
  }

  a {
    display: inline-block;
    position: relative;
    color: #008cd1;
    font-weight: 600;
    background-image: linear-gradient(to right, #47c5f3, #47c5f3);
    background-position: 0 93%;
    background-repeat: no-repeat;
    background-size: 100% 0px;

    :hover {
      background-size: 100% 2px;
    }
  }
`

export const Form = styled.form`
  display: ${(props) => props.submitted && "none"};
  @media ${device.desktop} {
    padding-right: 100px;
  }
`
export const SubmitButton = styled(Button)`
  width: 100%;
  cursor: pointer;

  @media ${device.tablet} {
    width: unset;
  }
`

export const Address = styled.address`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  margin-left: ${(props) => (!props.headOffice ? "0" : "34px")};

  display: ${(props) => !props.headOffice && "inline"};
`
export const ContactTextInput = styled(TextInput)`
  border: none;

  textarea {
    height: ${(props) => props.smallTextArea && "140px"};
  }
  ${Label} {
    font-family: ${(props) => props.theme.headerFont};
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #575a5e;

    :after {
      content: "";
      display: ${(props) => (props.required ? "inline-block" : "none")};
      background: #008cd1;
      width: 7px;
      height: 7px;
      border-radius: 7px;
      margin-left: 10px;
      margin-bottom: 2px;
    }

    @media ${device.tablet} {
      font-size: 18px;
      :after {
        margin-bottom: 3px;
      }
    }
  }

  ${Input} {
    background: #ffffff;
    color: #575a5e;
    font-size: 16px;
    border: 1px solid #aeb2b6;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    :focus {
      box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.15);
    }

    @media ${device.tablet} {
      font-size: 18px;
    }
  }

  ${TextInputErrorMessage} {
    color: ${(props) => props.theme.colors.darkerOrange};
    bottom: -19px;

    @media ${device.tablet} {
      bottom: -24px;
    }

    @media ${device.desktop} {
      bottom: -26px;
    }
  }
`

export const MandatoryMessage = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: #575a5e;
  margin-bottom: 25px;

  @media ${device.tablet} {
    font-size: 16px;
    margin-bottom: 0;
  }

  :before {
    content: "";
    display: inline-block;
    background: #008cd1;
    width: 7px;
    height: 7px;
    border-radius: 7px;
    margin-right: 8px;
    margin-bottom: 1px;
  }

  @media ${device.tablet} {
    :before {
      margin-right: 12px;
      margin-bottom: 2px;
    }
  }
`
export const ContactCardWrapper = styled.div`
  padding: 24px 24px 14px 24px;

  @media ${device.tablet} {
    padding: 30px 30px 23px 30px;
  }
`
export const ContactCardHeading = styled.h3`
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.01em;
  line-height: 150%;
  margin-bottom: 20px;

  @media ${device.desktop} {
    line-height: 120%;
    font-size: 22px;
  }

  @media ${device.large} {
    font-size: 24px;
    margin-bottom: 15px;
    line-height: 120%;
  }
`

export const ContactCardSubText = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: #fff;
  margin-bottom: 15px;
  display: ${(props) => !props.headOffice && "flex"};

  @media ${device.large} {
    font-size: 18px;
  }
`
export const ContactCardIcon = styled(Icon)`
  display: inline-block;
  margin-right: 18px;
  position: relative;
  top: ${(props) => (props.name === "home-2" ? `1px` : `3px`)};

  @media ${device.desktop} {
    top: ${(props) => (props.name === "home-2" ? `1px` : `5px`)};
  }
`
