import React, { useContext } from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { ContactTemplate } from "components/Templates/ContactTemplate"
import { parseBlockContent } from "helpers/parseBlockContent"
import { PreviewContext } from "context/PreviewContext"

const Contact = ({ data }) => {
  const { seo, featuredImage, avContactData, content } = data.page

  const { previewData } = useContext(PreviewContext)

  const parsedContent =
    previewData?.templateData?.content || content
      ? parseBlockContent(content)
      : null

  return (
    <Layout pageContext={data.page} key={data.page.uri}>
      <SEO
        title={seo.title}
        image={featuredImage?.node?.sourceUrl}
        description={seo.metaDesc}
      />
      <ContactTemplate contactData={avContactData} content={parsedContent} />
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      uri
      ancestors {
        nodes {
          ... on WpPage {
            depth
            title
            uri
          }
        }
      }
      avContactData {
        country
        cdDetails {
          headOffice
          address
          emailEnquiry
          mapImage {
            sourceUrl
            srcSet
          }
          phone
        }
        relatedPosts {
          items {
            ... on WpPost {
              title
              uri
              featuredImage {
                node {
                  sourceUrl
                  srcSet
                }
              }
              categories {
                nodes {
                  slug
                  name
                }
              }
            }
          }
        }
        ctaPage {
          ... on WpPage {
            title
            excerpt
            uri
          }
        }
      }
      seo {
        title
        metaDesc
      }
      headerSettings {
        customPageTitle
      }
      featuredImage {
        node {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
      }
      pageSettings {
        relatedHeading
        hideRelatedPages
        relatedPages {
          ... on WpPage {
            title
            excerpt
            uri
            featuredImage {
              node {
                sourceUrl
                srcSet
                mediaDetails {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`
